import getBusinessLocation from './getBusinessLocation';

const getPageDetails = ({
  business,
  pageName = 'Refrens Profile',
  includeLocationInPageTitle = true,
}) => {
  const { name, alias, locations = [], og = {} } = business || {};
  const title = alias || name;

  const businessLocation = getBusinessLocation(locations, true);

  const pageTitle = `${title}${
    includeLocationInPageTitle && !!businessLocation ? `, ${businessLocation}` : ''
  }${pageName ? ` - ${pageName}` : ''}`;

  const pageDescription = `${title} is on Refrens. Refrens is a community of the world's leading businesses that showcase their work and grow.`;

  const ogData = {
    title: pageTitle,
    image: og.image,
    description: pageDescription,
    type: 'website',
  };

  return { title, ogData, businessLocation };
};

export const getProfilePageDetails = (business) => {
  const { name, alias, locations = [], og, categories = [], teamMembers = 1 } = business || {};
  const title = alias || name;

  let city = '';
  if (locations?.length && locations[0] && locations[0]?.address) {
    city = locations[0].address?.city || '';
  }

  let topCategory = '';
  if (categories?.length) {
    // get category with the highest count
    [topCategory] = categories
      .sort((a, b) => b?.count - a?.count)
      .map((c) => c?.name)
      .filter(Boolean);
  }

  // page title for freelancers
  let pageTitle = `${title}${topCategory ? ` | ${topCategory}` : ''}${
    city ? `, ${city}` : ''
  } -Refrens`;

  if (teamMembers > 1) {
    // page title for agencies
    pageTitle = `${title}${city ? `, ${city}` : ''} -Refrens`;
  }

  const pageDescription = `${title} is on Refrens. Refrens is a community of the world's leading businesses that showcase their work and grow.`;

  const ogData = {
    title: pageTitle,
    image: og?.image,
    description: pageDescription,
    type: 'website',
  };

  return { title, ogData };
};

export default getPageDetails;
