import planMetaMapping from '@refrens/fence/premium/plan-meta-mapping.json';

const premiumPlanSubjectMapping = Object.entries(planMetaMapping || {}).reduce(
  (acc, [key, value]) => {
    const { subKey, useAsFallback = false } = value || {};
    if (subKey) {
      acc.subMap[key] = subKey;
    }
    if (useAsFallback) {
      acc.defaultKey = key;
    }

    return acc;
  },
  { subMap: {}, defaultKey: '' },
);

export default premiumPlanSubjectMapping;
