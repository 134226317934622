import React from 'react';
import PropTypes from 'prop-types';

import StaticImage, { ResponsiveImage } from '@/components/blocks/Images';
import EmptyStateLoader from './EmptyStateLoader';
import { EmptyStateContainer, BigHeading, SmallHeading } from './styles';

const Artwork = 'https://assets.refrens.com/Empty_Dashboard_750623532b.png';

const EmptyState = ({
  name,
  dashboard,
  cta,
  value,
  artwork,
  small = false,
  description,
  isResonsive = false,
}) => {
  const details = `Your dashboard is empty as of now! Start with creating ${dashboard.toLowerCase()} and things will show up here automatically.`;
  return (
    <EmptyStateContainer small={small}>
      {value === 0 ? (
        <>
          <div>
            <div>
              {artwork && isResonsive && <ResponsiveImage src={artwork} />}
              {artwork && !isResonsive && <StaticImage src={artwork} />}
              {!artwork && <ResponsiveImage src={Artwork} />}
            </div>
          </div>
          <div>
            {!small && <BigHeading>Hi {name}!</BigHeading>}
            <SmallHeading>{description || details}</SmallHeading>
          </div>
          <div>{cta}</div>
        </>
      ) : (
        <div>
          <EmptyStateLoader />
        </div>
      )}
    </EmptyStateContainer>
  );
};

EmptyState.propTypes = {
  name: PropTypes.string,
  dashboard: PropTypes.string,
  cta: PropTypes.element,
  value: PropTypes.number,
  artwork: PropTypes.string,
  description: PropTypes.string,
};

export default EmptyState;
