import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { inject, observer } from 'mobx-react';
import { useLazyEffect } from '@refrens/disco';
import { PremiumPopup, PremiumSuccess, PremiumSuccessVariant } from '@refrens/jupiter';
import planSubjectMapping from '@/constants/premiumPlanSubjectMapping';

const PremiumSuccessWrapper = styled.div`
  display: flex;
  margin: var(--sizes-regular) 0;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

const AutoTrial = (props) => {
  const { store, business, skipReload = false } = props || {};
  const [businessData, setBusinessData] = useState(business);
  const [autoTrialAnimation, setAutoTrialAnimation] = useState(false);
  const [openAutoTrialModal, setAutoTrialModal] = useState(false);

  const autoTrialRef = useRef();
  const trialModalKey = 'featuresUnlocked.autoTrialModal';
  const trialAnimationKey = 'featuresUnlocked.autoTrialAnimation';

  useEffect(() => {
    setBusinessData(business);
  }, [business]);

  const isOnTrial = store.isOnTrial(businessData);
  const { featuresUnlocked, urlKey, _systemMeta: bizMeta } = businessData || {};
  const { autoTrialModal, autoTrialAnimation: trialAnimation } = featuresUnlocked || {};
  const { autoTrialSource } = bizMeta || {};

  const patchBusiness = useCallback(
    async (key) => {
      const bizPath = `/businesses/${urlKey}`;
      const bizResponse = await store.api.patch(bizPath, { [key]: true });
      setBusinessData(bizResponse.data);
    },
    [urlKey],
  );

  /**
   * @description This effect is used to open the auto trial modal
   * If the user is on trial and the trialAnimation flag is set in businessData as true
   * Also autoTrialModal flag is set to false and not undefined in businessData
   * Then it indicates that the user has seen the animation and the modal is not opened yet
   */
  useLazyEffect(() => {
    if (
      isOnTrial &&
      trialAnimation &&
      typeof autoTrialModal !== 'undefined' &&
      !autoTrialModal &&
      !autoTrialAnimation
    ) {
      setAutoTrialModal(true);
      if (autoTrialRef?.current) {
        // eslint-disable-next-line no-unused-expressions
        autoTrialRef?.current?.open();
        // eslint-enable-next-line no-unused-expressions
      }
    }
  }, [isOnTrial, autoTrialModal, trialAnimation, autoTrialAnimation]);

  /**
   * @description This effect is used to open the auto trial animation
   * If the user is on trial and the trialAnimation flag is set in businessData as false
   * And autoTrialModal flag is set to false and not undefined in businessData
   * Then it indicates that the user has not seen the animation and the modal is not opened yet
   * So we start the animation and set the trialAnimation flag to true in businessData as soon as the animation starts
   * This is done to prevent the animation from starting again if the user refreshes the page
   * After the animation is complete, an event is fired which opens the auto trial modal
   */
  useLazyEffect(() => {
    if (isOnTrial && typeof trialAnimation !== 'undefined' && !trialAnimation && !autoTrialModal) {
      setAutoTrialAnimation(true);
      setTimeout(() => {
        patchBusiness(trialAnimationKey, true);
      }, 1000);
    }
  }, [isOnTrial, autoTrialModal, trialAnimation]);

  /**
   * @description This function is used to open the auto trial modal
   * This is called when the animation is completed and the modal is not opened yet
   * It sets the autoTrialModal flag to true in businessData
   */
  const handleAutoTrialActivation = useCallback(async () => {
    setAutoTrialAnimation(false);
    setAutoTrialModal(true);
    if (autoTrialRef?.current) {
      autoTrialRef.current.open();
    }
  }, [urlKey, trialAnimationKey]);

  /**
   * @description This function is used to close the auto trial modal
   * This is called when the user clicks on the cancel button in the modal
   * It sets the autoTrialModal flag to true in businessData
   * This prevents the modal from opening again if the user refreshes the page
   */
  const handleCancel = useCallback(async () => {
    setAutoTrialModal(false);
    await patchBusiness(trialModalKey);
    /**
     * For bulk upload, we don't want to reload the page as we have already reloaded it once after the upload
     * Skip reload is passed as true from the invoices review page as a lottie animation is shown there which reloads the page
     */
    if (autoTrialSource !== 'BULK_UPLOAD' && !skipReload) {
      window.location.reload();
    }
  }, [trialModalKey, autoTrialSource, skipReload]);

  if (!autoTrialAnimation && !openAutoTrialModal) return <></>;

  return (
    <>
      {autoTrialAnimation && (
        <PremiumSuccessWrapper>
          <PremiumSuccess
            lottievariant={PremiumSuccessVariant.AUTOTRIALACTIVATION}
            onComplete={() => handleAutoTrialActivation()}
          />
        </PremiumSuccessWrapper>
      )}
      {openAutoTrialModal && (
        <PremiumPopup
          ref={autoTrialRef}
          variant='AUTOTRIAL'
          setVisible={setAutoTrialModal}
          onCancel={handleCancel}
          feature='Direct'
          premiumType={planSubjectMapping?.defaultKey}
        />
      )}
    </>
  );
};

const AutoTrialModule = inject('store')(observer(AutoTrial));

export default AutoTrialModule;
