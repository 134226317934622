import React from 'react';
import styled from 'styled-components';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { staticDomain } = publicRuntimeConfig;

export const ResponsiveImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

function StaticImage(props) {
  const { src, ...imgProps } = props;
  return <ResponsiveImage src={staticDomain + src} {...imgProps} />;
}

export default StaticImage;
